import React from "react";
import {inject, observer} from "mobx-react";
import {Accordion, Button, Checkbox, Form, Grid, Modal, Segment, Table} from "semantic-ui-react";
import config from "../../config/main.config";
import {getTokenFromLocalStorage, isAdmin} from "../../helper/util";
import userStore from "../../stores/userStore";
import history from "../../helper/browserHistory";
import {
    CartesianGrid,
    Line,
    LineChart,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Bar,
    Label
} from "recharts";
import Legend from "recharts/lib/component/Legend";
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";

@inject("stores")
@observer
class UserManagementPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authors: [],
            userAddCategories: [],
            categories: [],
            trackingChart: "",
            downloadTrackingChart: "",
            selectedKW: new Date().getFullYear() + "-W" + this.getWeek(),
            loading: true,
            addModalOpen: false,
            editModalOpen: false,
            editUserIndex: 0,
            //User data used for add and edit
            //ALWAYS RESET AFTER USING
            name: "",
            nameError: false,
            lastName: "",
            lastNameError: false,
            username: "",
            usernameError: false,
            email: "",
            password: "",
            passwordError: false,
            admin: false,
            userId: "",
            modalActiveIndex: -1,
            deleteModalOpen: false,
            deleteUserId: "",
            errorMessage: "",
            errorModalOpen: false,
            categoryNameError: false,
            categoryName: "",
            categoryModalOpen: false,
            categoryDeleteModalOpen: false,
            categoryId: 0,
            categoryEditModalOpen: false,
            activeIndex: -1,
            subCategoryModalOpen: false,
            subCategoryDeleteModalOpen: false,
            subCategoryId: -1,
            subCategoryEditModalOpen: false,
            tracking: []
        }

    }

    /**
     * Scrolls page up when loaded
     */
    componentDidMount() {
        if (userStore.userFromServer == null || !isAdmin()) {
            history.push("/");
        }
        window.scrollTo(0, 0);
        this.getUsers();
        this.getCategories();
        this.props.stores.trackingStore.fetchTracking().then(tracks => {
            this.setState({
                ...this.state,
                trackingChart: this.getTrackingChart(tracks),
                downloadTrackingChart: this.getDownloadTrackingCharts(tracks),
                tracking: tracks
            });
            this.setState({...this.state});
        });
    }

    getWeek() {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));

        return Math.ceil(days / 7);

    }

    getCategories() {
        fetch(config.BASE_URL + 'blog/categories', {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                this.setState({...this.state, categories: data.categories})
                this.createAddUserCategories();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getUsers() {
        fetch(config.BASE_URL + "users/all", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                this.setState({...this.state, loading: false, authors: data.authors})
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    createAddUserCategories() {
        let userCategories = [];

        //todo -\_(°-°)_/-
        for (let i = 0; i < this.state.categories.length; i++) {
            let category = {
                name: this.state.categories[i].name,
                id: this.state.categories[i].id
            };
            let sub_categories = [];
            for (let j = 0; j < this.state.categories[i].sub_categories.length; j++) {
                sub_categories.push({
                    name: this.state.categories[i].sub_categories[j].name,
                    id: this.state.categories[i].sub_categories[j].id,
                    active: false
                })
            }
            category.sub_categories = sub_categories;
            userCategories.push(category)

        }
        this.setState({...this.state, userAddCategories: userCategories})
    }

    changeCheckbox(index, id) {
        fetch(config.BASE_URL + 'users/admin', {
            method: 'PUT',
            headers: {
                'id': id,
                'admin': this.state.authors[index].admin ? 0 : 1,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        this.getUsers()
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    showDeleteModal(id) {
        if (this.state.authors.length > 1) {
            this.setState({
                ...this.state,
                deleteUserId: id,
                deleteModalOpen: true
            })
        }
    }

    deleteUser() {
        fetch(config.BASE_URL + 'users/' + this.state.deleteUserId, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getUsers();
                        this.setState({
                            ...this.state,
                            deleteUserId: "",
                            deleteModalOpen: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    usernameExists(username, edit = false) {
        for (let i = 0; i < this.state.authors.length; i++) {
            if (this.state.authors[i].username === username) {
                if (edit) {
                    if (i !== this.state.editUserIndex) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    /*
     * Returns true if a needed parameter is empty
     */
    isEmptyForm(edit = false) {
        let isEmpty = false;
        if (this.state.username === "") {
            this.setState({...this.state, usernameError: true});
            isEmpty = true;
        } else {
            this.setState({...this.state, usernameError: false});
        }
        if (this.state.password === "" && !edit) {
            this.setState({...this.state, passwordError: true});
            isEmpty = true;
        } else {
            this.setState({...this.state, passwordError: false});
        }
        if (this.state.name === "") {
            this.setState({...this.state, nameError: true});
            isEmpty = true;
        } else {
            this.setState({...this.state, nameError: false});
        }
        if (this.state.lastName === "") {
            this.setState({...this.state, lastNameError: true});
            isEmpty = true;
        } else {
            this.setState({...this.state, lastNameError: false});
        }

        return isEmpty;
    }

    getActiveCategories() {
        let active_subcategories = [];

        for (let i = 0; i < this.state.userAddCategories.length; i++) {
            for (let j = 0; j < this.state.userAddCategories[i].sub_categories.length; j++) {
                if (this.state.userAddCategories[i].sub_categories[j].active)
                    active_subcategories.push(
                        this.state.userAddCategories[i].sub_categories[j].id
                    )
            }
        }

        return active_subcategories;
    }

    addUser() {
        if (this.isEmptyForm()) {
            return;
        }
        if (this.usernameExists(this.state.username)) {
            this.setState({
                ...this.state,
                errorMessage: "User already exists",
                errorModalOpen: true
            })
            return;
        }

        let activeCategories = this.getActiveCategories();
        let self = this;

        fetch(config.BASE_URL + 'users/add', {
            method: 'POST',
            headers: {
                'username': this.state.username,
                'password': this.state.password,
                'admin': this.state.admin ? 1 : 0,
                'firstname': this.state.name,
                'lastname': this.state.lastName,
                'email': this.state.email,
                'subcategories': activeCategories.join(),
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getUsers();
                        this.getCategories();
                        self.setState({
                            ...this.state,
                            addModalOpen: false,
                            name: "",
                            username: "",
                            password: "",
                            admin: false,
                            nameError: false,
                            usernameError: false,
                            passwordError: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })

                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    editUser() {
        if (this.isEmptyForm(true)) {
            return;
        }
        if (this.usernameExists(this.state.username, true)) {
            this.setState({
                ...this.state,
                errorMessage: "User already exists",
                errorModalOpen: true
            })
            return;
        }
        let activeCategories = this.getActiveCategories();
        fetch(config.BASE_URL + 'users/update', {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: this.state.userId,
                username: this.state.username,
                admin: this.state.admin ? 1 : 0,
                firstname: this.state.name,
                lastname: this.state.lastName,
                email: this.state.email,
                subcategories: activeCategories.join()
            })
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        this.setState({
                            ...this.state,
                            editModalOpen: false,
                            name: "",
                            username: "",
                            password: "",
                            admin: false,
                            nameError: false,
                            usernameError: false,
                            passwordError: false
                        });
                        //Load Users
                        this.getUsers();
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    handleInputChange(type, event) {
        switch (type) {
            case 'name':
                this.setState({...this.state, name: event.target.value});
                break;
            case 'lastName':
                this.setState({...this.state, lastName: event.target.value});
                break;
            case 'username':
                this.setState({...this.state, username: event.target.value});
                break;
            case 'password':
                this.setState({...this.state, password: event.target.value});
                break;
            case 'email':
                this.setState({...this.state, email: event.target.value});
                break;
            case 'admin':
                this.setState(prevState => ({...this.state, admin: prevState.admin}));
                break;
            case 'category-name':
                this.setState({...this.state, categoryName: event.target.value});
                break;
            default:
                break;
        }
    }

    openModal(open) {
        this.setState({
            ...this.state,
            addModalOpen: open,
            name: "",
            lastName: "",
            username: "",
            admin: false,
            nameError: false,
            usernameError: false,
            passwordError: false
        });
    }

    showEditModal(index, id) {
        let statorini = this.state;
        statorini.name = this.state.authors[index].firstName;
        statorini.lastName = this.state.authors[index].lastName;
        statorini.username = this.state.authors[index].username;
        statorini.admin = this.state.authors[index].admin;
        statorini.email = this.state.authors[index].email;
        for (let i = 0; i < this.state.userAddCategories.length; i++) {
            for (let j = 0; j < this.state.userAddCategories[i].sub_categories.length; j++) {
                let catId = this.state.userAddCategories[i].sub_categories[j].id;
                if (this.state.authors[index].subcategories.indexOf(catId) > -1)
                    statorini.userAddCategories[i].sub_categories[j].active = true;

            }
        }
        statorini.userId = id;
        statorini.editModalOpen = true;
        statorini.editUserIndex = index;
        statorini.nameError = false;
        statorini.usernameError = false;


        this.setState(statorini);
    }

    openNewCategoryModal() {
        this.setState({
            ...this.state,
            categoryNameError: false,
            categoryName: "",
            categoryModalOpen: true
        })
    }

    openNewSubCategoryModal(id) {
        this.setState({
            ...this.state,
            categoryNameError: false,
            categoryName: "",
            subCategoryModalOpen: true,
            categoryId: id
        })
    }

    addSubCategory() {
        if (this.state.categoryName === "") {
            this.setState({...this.state, categoryNameError: true});
            return;
        }

        fetch(config.BASE_URL + 'blog/subcategory', {
            method: 'POST',
            headers: {
                'name': this.state.categoryName,
                'categoryid': this.state.categoryId,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getCategories();
                        this.setState({
                            ...this.state,
                            categoryNameError: false,
                            categoryName: "",
                            subCategoryModalOpen: false,
                            categoryId: -1
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    addCategory() {
        if (this.state.categoryName === "") {
            this.setState({...this.state, categoryNameError: true})
            return;
        }

        fetch(config.BASE_URL + 'blog/category', {
            method: 'POST',
            headers: {
                'name': this.state.categoryName,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                    if (data.hasOwnProperty("success")) {
                        if (data.success) {
                            //Load Users
                            this.getCategories();
                            this.setState({
                                ...this.state,
                                categoryNameError: false,
                                categoryName: "",
                                categoryModalOpen: false
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                errorMessage: data.message,
                                errorModalOpen: true
                            })
                        }
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: "Network error",
                            errorModalOpen: true
                        })
                    }
                }
            )
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    showDeleteSubCategoryModal(id) {
        this.setState({
            ...this.state,
            subCategoryId: id,
            subCategoryDeleteModalOpen: true
        })
    }

    deleteCategory() {
        fetch(config.BASE_URL + 'blog/category', {
            method: 'DELETE',
            headers: {
                'id': this.state.categoryId,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getCategories();
                        this.setState({
                            ...this.state,
                            categoryId: 0,
                            categoryDeleteModalOpen: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    deleteSubCategory() {
        fetch(config.BASE_URL + 'blog/subcategory', {
            method: 'DELETE',
            headers: {
                'id': this.state.subCategoryId,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getCategories();
                        this.setState({
                            ...this.state,
                            subCategoryId: 0,
                            subCategoryDeleteModalOpen: false
                        })

                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    showEditSubCategoryModal(id, name) {
        this.setState({
            ...this.state,
            categoryId: id,
            categoryName: name,
            subCategoryEditModalOpen: true
        })
    }

    editCategory() {
        fetch(config.BASE_URL + 'blog/category', {
            method: 'PUT',
            headers: {
                'id': this.state.categoryId,
                'name': this.state.categoryName,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getCategories();
                        this.setState({
                            ...this.state,
                            categoryId: 0,
                            categoryName: "",
                            categoryEditModalOpen: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    editSubCategory() {
        fetch(config.BASE_URL + 'blog/subcategory', {
            method: 'PUT',
            headers: {
                'id': this.state.categoryId,
                'name': this.state.categoryName,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        //Load Users
                        this.getCategories();
                        this.setState({
                            ...this.state,
                            categoryId: 0,
                            categoryName: "",
                            subCategoryEditModalOpen: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            errorMessage: data.message,
                            errorModalOpen: true
                        })
                    }
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: "Network error",
                        errorModalOpen: true
                    })
                }
            })
            .catch(function (error) {
                console.log("bad", error);
            });
    }

    handleAccordionClick = (e, titleProps) => {
        const {index} = titleProps;
        if (this.state.activeIndex === index) {
            this.setState({...this.state, activeIndex: -1})
        } else {
            this.setState({...this.state, activeIndex: index})
        }
    };

    handleSubAccordionClick = (e, titleProps) => {
        const {index} = titleProps;
        if (this.state.modalActiveIndex === index) {
            this.setState({...this.state, modalActiveIndex: -1})
        } else {
            this.setState({...this.state, modalActiveIndex: index})
        }
    };

    updateUserCategories(category, subcategory) {
        this.state.userAddCategories[category].sub_categories[subcategory].active = !this.state.userAddCategories[category].sub_categories[subcategory].active;
    }

    getUserAccordion() {
        let tableCategories = [];
        for (let i = 0; i < this.state.userAddCategories.length; i++) {
            let header =
                <Accordion.Title active={this.state.modalActiveIndex === i} index={i}
                                 onClick={this.handleSubAccordionClick} key={"H" + i}>
                    <Grid>
                        <Grid.Row columns={2} width={16}>
                            <Grid.Column width={14}>
                                <p style={{"marginBottom": "0px"}}>{this.state.userAddCategories[i].name}</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Title>;

            tableCategories.push(header);

            let subCategories = [];

            for (let j = 0; j < this.state.userAddCategories[i].sub_categories.length; j++) {
                let subCategory = this.state.userAddCategories[i].sub_categories[j];
                subCategories.push(
                    <Grid.Row key={i}>
                        <Grid.Column width={14}>
                            <p style={{"marginBottom": "0px"}}>{subCategory.name}</p>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Grid.Column width={2}>
                                <Checkbox checked={subCategory.active} floated='right'
                                          onChange={this.updateUserCategories.bind(this, i, j)}
                                />
                            </Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                )
            }

            let content =
                <Accordion.Content active={this.state.modalActiveIndex === i} key={"C" + i}>
                    <Segment>
                        <Grid>
                            {subCategories}
                        </Grid>
                    </Segment>
                </Accordion.Content>;

            tableCategories.push(content)
        }

        return (
            <Accordion styled fluid>
                {tableCategories}
            </Accordion>);
    }

    /**
     * Returns Blog page
     * @return {XML} html of this component
     */
    render() {


        let tableAuthors = this.state.authors.map((author, index) =>
            <Table.Row key={"author" + index}>
                <Table.Cell>{author.id}</Table.Cell>
                <Table.Cell>{author.firstName + " " + author.lastName}</Table.Cell>
                <Table.Cell>{author.username}</Table.Cell>
                <Table.Cell>{author.email}</Table.Cell>
                <Table.Cell>
                    <input type="checkbox"
                           value="default"
                           checked={author.admin}
                           onChange={this.changeCheckbox.bind(this, index, author.id)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <button className="ui basic icon negative button"
                            onClick={this.showDeleteModal.bind(this, author.id)}>
                        <i aria-hidden="true"
                           className="remove icon"/>
                    </button>
                </Table.Cell>
                <Table.Cell>
                    <button className="ui basic icon positive button"
                            onClick={() => this.showEditModal(index, author.id)}>
                        <i aria-hidden="true"
                           className="edit icon"/>
                    </button>
                </Table.Cell>
            </Table.Row>
        );

        let tableCategories = [];
        for (let i = 0; i < this.state.categories.length; i++) {
            let category = this.state.categories[i];
            let header =
                <Accordion.Title active={this.state.activeIndex === i} index={"cat" + i}
                                 onClick={this.handleAccordionClick}
                                 key={"H" + i}>
                    <Grid>
                        <Grid.Row columns={3} width={16}>
                            <Grid.Column width={12}>
                                <h3 style={{"marginBottom": "0px"}}>{category.name}</h3>
                                <h4 style={{"marginTop": "0px"}}>{category.id}</h4>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button className="ui basic icon negative button" floated='right'
                                        onClick={() => {
                                            let start = this.state;
                                            start.categoryId = category.id;
                                            start.categoryDeleteModalOpen = true;
                                            this.setState({...this.state});
                                        }}>
                                    <i aria-hidden="true"
                                       className="remove icon"/>
                                </Button>
                                <Button className="ui basic icon positive button" floated='right'
                                        onClick={() => {
                                            let start = this.state;
                                            start.categoryId = category.id;
                                            start.categoryName = category.name;
                                            start.categoryEditModalOpen = true;
                                            this.setState({...this.state});
                                        }}>
                                    <i aria-hidden="true"
                                       className="edit icon"/>
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Accordion.Title>;

            tableCategories.push(header);

            let subCategories = [];

            for (let j = 0; j < category.sub_categories.length; j++) {
                let subCategory = category.sub_categories[j];
                subCategories.push(
                    <Grid.Row key={subCategory.name + j}>
                        <Grid.Column width={12}>
                            <h4 style={{"marginBottom": "0px"}}>{subCategory.name}</h4>
                            <h5 style={{"marginTop": "0px"}}>{subCategory.id}</h5>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button className="ui basic icon negative button" floated='right'
                                    onClick={this.showDeleteSubCategoryModal.bind(this, subCategory.id)}>
                                <i aria-hidden="true"
                                   className="remove icon"/>
                            </Button>
                            <Button className="ui basic icon positive button" floated='right'
                                    onClick={this.showEditSubCategoryModal.bind(this, subCategory.id, subCategory.name)}>
                                <i aria-hidden="true"
                                   className="edit icon"/>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                )
            }

            let content =
                <Accordion.Content active={this.state.activeIndex === i} key={"C" + i}>
                    <Segment>
                        <Grid>
                            {subCategories}
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Button positive
                                            onClick={this.openNewSubCategoryModal.bind(this, category.id)}>Hinzufügen</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Accordion.Content>;

            tableCategories.push(content)
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h2>Users</h2>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Username</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Admin</Table.HeaderCell>
                                    <Table.HeaderCell>Entfernen</Table.HeaderCell>
                                    <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableAuthors}
                            </Table.Body>
                        </Table>
                        <Button positive onClick={this.openModal.bind(this, true)}>Hinzufügen</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h2>Kategorien</h2>
                        <Accordion styled fluid style={{"marginBottom": "14px"}}>
                            {tableCategories}
                        </Accordion>
                        <Button positive onClick={this.openNewCategoryModal.bind(this, true)}>Hinzufügen</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>{"Aufgerufene Seiten "+new Date().getFullYear() }</h2>
                        {this.state.trackingChart}
                        <h2>Kalenderwochen</h2>
                        <input type={'week'} value={this.state.selectedKW} style={{marginRight: '1rem'}}
                               onChange={(e) => {
                                   this.setState({
                                       ...this.state,
                                       selectedKW: e.target.value
                                   })
                               }}/>
                        <Button icon={'search'} onClick={_ => {
                            this.setState({
                                ...this.state,
                                downloadTrackingChart: this.getDownloadTrackingCharts(this.state.tracking)
                            })
                        }}>Suchen</Button>
                        <Button className={'call-to-action-button'} onClick={_ => {
                            if (typeof document !== "undefined") {
                                domtoimage.toBlob(document.getElementById('charts-for-download'), {})
                                    .then((blob) => {
                                        fileDownload(blob, `Bürgerstiftung-Seitenstatistik-KW${this.state.selectedKW}.png`);
                                    })
                            }
                        }}>Download</Button>
                        {this.state.downloadTrackingChart}
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Modal open={this.state.addModalOpen} onClose={() => this.openModal(false)}
                               className={"margin-auto"}>
                            <Modal.Header>User hinzufügen</Modal.Header>
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Input fluid label='Vorname' placeholder='Vorname' type="text"
                                                value={this.state.name}
                                                onChange={this.handleInputChange.bind(this, 'name')}
                                                error={this.state.nameError}/>
                                    <Form.Input fluid label='Nachname' placeholder='Nachname' type="text"
                                                value={this.state.lastName}
                                                onChange={this.handleInputChange.bind(this, 'lastName')}
                                                error={this.state.lastNameError}/>

                                    <Form.Input fluid label='Username' placeholder='Username' type="text"
                                                value={this.state.username}
                                                onChange={this.handleInputChange.bind(this, 'username')}
                                                error={this.state.usernameError}/>

                                    <Form.Input fluid label='Passwort' placeholder='Passwort' type="password"
                                                value={this.state.password}
                                                onChange={this.handleInputChange.bind(this, 'password')}
                                                error={this.state.passwordError}/>
                                    <Form.Input fluid label='Email' placeholder='email' type="text"
                                                value={this.state.email}
                                                onChange={this.handleInputChange.bind(this, 'email')}/>
                                    <Form.Field>
                                        <Checkbox label='Admin' checked={this.state.admin}
                                                  onChange={this.handleInputChange.bind(this, 'admin')}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Kategorien</label>
                                        {this.getUserAccordion()}
                                    </Form.Field>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Hinzufügen'
                                        onClick={this.addUser.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.deleteModalOpen}
                               onClose={() => this.setState({...this.state, deleteModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>User entfernen</Modal.Header>
                            <Modal.Content>
                                <p>Sind Sie sich sicher diesen User zu löschen?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Löschen'
                                        onClick={this.deleteUser.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.editModalOpen}
                               onClose={() => this.setState({...this.state, editModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>User bearbeiten</Modal.Header>
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Input fluid label='Vorname' placeholder='Vorname' type="text"
                                                value={this.state.name}
                                                onChange={this.handleInputChange.bind(this, 'name')}
                                                error={this.state.nameError}
                                    />
                                    <Form.Input fluid label='Nachname' placeholder='Nachname' type="text"
                                                value={this.state.lastName}
                                                onChange={this.handleInputChange.bind(this, 'lastName')}
                                                error={this.state.lastNameError}/>

                                    <Form.Input fluid label='Username' placeholder='Username' type="text"
                                                value={this.state.username}
                                                onChange={this.handleInputChange.bind(this, 'username')}
                                                error={this.state.usernameError}
                                    />
                                    <Form.Input fluid label='Email' placeholder='email' type="text"
                                                value={this.state.email}
                                                onChange={this.handleInputChange.bind(this, 'email')}/>
                                    <Form.Field>
                                        <label>Kategorien</label>
                                        {this.getUserAccordion(this.state.userId)}
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Admin' checked={this.state.admin}
                                                  onChange={this.handleInputChange.bind(this, 'admin')}/>
                                    </Form.Field>
                                </Form>

                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Bearbeiten'
                                        onClick={this.editUser.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.errorModalOpen}
                               onClose={() => this.setState({...this.state, errorModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Error</Modal.Header>
                            <Modal.Content>
                                <p>{this.state.errorMessage}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='OK'
                                        onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                errorModalOpen: false,
                                                errorMessage: ""
                                            })
                                        }}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.categoryModalOpen}
                               onClose={() => this.setState({...this.state, categoryModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Kategorie hinzufügen</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name' type="text"
                                                value={this.state.categoryName}
                                                onChange={this.handleInputChange.bind(this, 'category-name')}
                                                error={this.state.categoryNameError}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Bearbeiten'
                                        onClick={this.addCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.subCategoryModalOpen}
                               onClose={() => this.setState({...this.state, subCategoryModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Unterkategorie hinzufügen</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name' type="text"
                                                value={this.state.categoryName}
                                                onChange={this.handleInputChange.bind(this, 'category-name')}
                                                error={this.state.categoryNameError}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Hinzufügen'
                                        onClick={this.addSubCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.categoryDeleteModalOpen}
                               onClose={() => this.setState({...this.state, categoryDeleteModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Kategorie entfernen</Modal.Header>
                            <Modal.Content>
                                <p>Sind Sie sich sicher diese Kategorie zu löschen?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Löschen'
                                        onClick={this.deleteCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.subCategoryDeleteModalOpen}
                               onClose={() => this.setState({...this.state, subCategoryDeleteModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Unterkategorie entfernen</Modal.Header>
                            <Modal.Content>
                                <p>Sind Sie sich sicher diese Unterkategorie zu löschen?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Löschen'
                                        onClick={this.deleteSubCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.categoryEditModalOpen}
                               onClose={() => this.setState({...this.state, categoryEditModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Kategorie bearbeiten</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name' type="text"
                                                value={this.state.categoryName}
                                                onChange={this.handleInputChange.bind(this, 'category-name')}
                                                error={this.state.categoryNameError}
                                    />
                                </Form>

                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Bearbeiten'
                                        onClick={this.editCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>
                        <Modal open={this.state.subCategoryEditModalOpen}
                               onClose={() => this.setState({...this.state, subCategoryEditModalOpen: false})}
                               className={"margin-auto"}>
                            <Modal.Header>Kategorie bearbeiten</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name' type="text"
                                                value={this.state.categoryName}
                                                onChange={this.handleInputChange.bind(this, 'category-name')}
                                                error={this.state.categoryNameError}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right' content='Bearbeiten'
                                        onClick={this.editSubCategory.bind(this)}/>
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }


    getDownloadTrackingCharts(tracking) {
        let selectedYear = parseInt(this.state.selectedKW.substring(0, this.state.selectedKW.indexOf('-')));
        let selectedWeek = parseInt(this.state.selectedKW.substring(this.state.selectedKW.indexOf('W') + 1, this.state.selectedKW.length));
        let data = [];
        for (let i = 0; i < tracking.length; i++) {
            let track = tracking[i];
            if (track.year === selectedYear && track.week === selectedWeek) {
                data.push({name: [track.link], value: track.amount})
            }
        }
        return (
            <div id={'charts-for-download'} style={{display: 'grid'}}>
                <ResponsiveContainer width="100%" height={400} className="background-white">
                    <BarChart
                        barCategoryGap="30"
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 20,
                        }}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name" tickLine={10} interval={0}>
                            <Label value={"Kalender Woche: " + selectedWeek} offset={0}
                                   position="bottom"/>
                        </XAxis>
                        <YAxis>
                            <Label angle={-90} value={"Besuche insgesamt"} offset={0}
                                   position="left"/>
                        </YAxis>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="value"   fill={'#' + Math.floor(Math.random() * 16777215 + 100).toString(16)}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }

    getTrackingChart(tracking) {
        let data = [];
        let lineData = [];
        let lines = [];
        /*
         * x = week
         *
         *  <Line type="monotone" dataKey="seen" stroke="#8884d8"/>
         */
        for (let i = 0; i < tracking.length; i++) {

            let track = tracking[i];
            let weekIn = false;
            if(new Date().getFullYear() === track.year){


            //check if this week of tracking is available
            for (let j = 0; j < data.length; j++) {
                if (data[j].x === track.week) {
                    weekIn = true;
                    //add data-point to data
                    data[j][track.link] = track.amount;
                    break;
                }
            }


            if (!weekIn) {
                let newData = {x: track.week};
                newData[track.link] = track.amount;
                data.push(newData);
            }

            //check if line is available
            if (!lineData.includes(track.link)) {
                lineData.push(track.link)
            }
            }
        }


        data.pageAmount = [...new Set(tracking.map(track => JSON.stringify(track.link)))][0].size


        //sort after KW (small to big);
        data = data.sort(function (a, b) {
            if (a.x < b.x) {
                return -1;
            }
            if (b.x < a.x) {
                return 1;
            }
            return 0;
        });
        //check if every data has every data-point
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < lineData.length; j++) {
                if (!data[i].hasOwnProperty(lineData[j])) {
                    data[i][lineData[j]] = 0;
                }
            }
        }

        for (let i = 0; i < lineData.length; i++) {
            lines.push(<Line
                type="monotone"
                dataKey={lineData[i]}
                stroke={'#' + Math.floor(Math.random() * 16777215 +100).toString(16)}
                strokeWidth={1.5}
            />);
        }
        return <>
            <ResponsiveContainer style={{background: 'white'}}
                                 width="100%" height={450}>
                <LineChart data={data}>
                    <Legend verticalAlign="top"/>
                    <CartesianGrid stroke="#ccc"/>
                    <XAxis dataKey={"x"}/>
                    <Tooltip/>
                    <YAxis allowDecimals={false}/>
                    {lines}
                </LineChart>
            </ResponsiveContainer>
        </>;
    }
}

export default UserManagementPage